/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Login } from './components/Login'
import { Registration } from './components/Registration'
import VerifyEmail from './components/verifyuseremail'
// import ForgotPswd from './components/forgotPassword'
import {AuthLayout} from './AuthLayout'
import { Landing } from './Landing'
import Trigger from './components/trigger'


const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
    <Route path='landing' element={<Landing />} />
     <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='verifyemail' element={<VerifyEmail />} />
      <Route path='trigger' element={< Trigger/>} /> 
    </Route>
    <Route index element={<Landing />} /> 
  </Routes>
)

export {AuthPage}
