
import clsx from 'clsx'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {getAccountsettings, getBranding} from '../../../../service/utility'
import { useState } from 'react'


 function init() {
  return new Promise(async (res, rej) => {
    var branding;
    do{
       branding = getBranding();
       await new Promise(r => setTimeout(r, 1000));
    }while(!branding)
    res(branding);
  })
}

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const [BrandLogo, setBrandLogo] = useState(toAbsoluteUrl('/media/logos/Optiva_Logo_white.png'));
  const [BrandTitle, setBrandTitle] = useState('Chatbot');
  init().then((d)=>{
    var branding = getBranding()
    if (branding) {
      setBrandLogo(branding.brandLogo ? branding.brandLogo : BrandLogo);
      setBrandTitle(branding.brandTitle ? branding.brandTitle : BrandTitle);
    }
  });

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
    >
      <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
             <i className='fa fa-menu'></i>
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}

        <div
          className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
          title='Show aside menu'
        >
          <div
            className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
         <i className='fa fa-menu'></i>
          </div>
        </div>

        <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
          <Link to='/'>
            {/* <img
              alt='Logo'
              src={BrandLogo}
              className='logo-default h-45px'
            />
            <img
              alt='Logo'
              src={BrandLogo}
              className='logo-sticky h-45px'
            /> */}
              <h1 className='fs-2hx fw-bold mb-0'>TelcoX</h1>
              <span className="d-block text-gray-800 fs-7">Powered by <span className="fs-5 fw-bold">Optiva</span></span>
          </Link>
        </div>

        
        <div className='d-flex align-items-stretch justify-content-end flex-lg-grow-1'>
           {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

         {/* {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )} */}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
