/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import './landing.scss';
export function Landing() {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div>
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">

          <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">

            <a>
              {/* <img
             alt='Logo'
             src={toAbsoluteUrl('/media/logos/Optiva_Logo_white.png')}
             className='theme-light-show h-45px'
           ></img>*/}
              <h1 className='fs-2hx fw-bold mb-0'>TelcoX</h1>
              <span className="d-block text-white fs-7">Powered by <span className="fs-5 fw-bold">Optiva</span></span>
            </a>

          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
              <li><a className="nav-link scrollto" href="#about">About</a></li>
              <li><a className="nav-link scrollto" href="#about">Services</a></li>
              <li><a className="nav-link scrollto" href="#footer">Contact</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          <Link to='/auth/login'><button id="login_entrar" className="btn-padrao btn-menu login_entrar" type="button">LOGIN </button></Link>
        </div>
      </header>


      <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
        <div className="container" data-aos="fade-in">
          <h1>Welcome to TelcoX</h1>
          <h2>TelcoX products are your engine for growth and innovation</h2>
          <div className="d-flex align-items-center">
            <i className="fa-solid fa-arrow-right get-started-icon"></i>
            <Link className="btn-get-started scrollto" to='/auth/registration'>Get Started</Link>
          </div>
        </div>



        {/* <div className='d-flex align-items-center justify-content-center w-100 h-100 '>
      <div className='col-lg-2 col-sm-1'>
        </div>
        <div className='col-md-6 col-sm-7'>
          <h1>Welcome to TelcoX</h1>
          <h2> Optiva products are your engine for growth and innovation</h2>
          <div className="d-flex align-items-center">
            <i className="fa-solid fa-arrow-right get-started-icon"></i>
            <Link className="btn-get-started scrollto" to='/auth/registration'>Get Started</Link>
          </div>
          </div>
          <div className='col-md-4 h-100'>
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100 pt-5 h-100 bgi-size-cover bgi-position-center'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/dozzy-1/17.png')})`, backgroundColor: `#040714`}}>
          </div>
        </div>
      </div>  */}
      </section>

      <main id="main" className='bg-white'>
        <section className="about">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h1 className='highLight mb-6'>Leading telecoms trust Optiva for innovative BSS solutions</h1>
              <h4 className='text-dark'>SIMPLIFY. UNIFY. MONETIZE.</h4>
            </div>
          </div>
        </section>
        <section id="about" className="about section-bg">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>About Us</h2>
              <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>

            <div className="row">
              <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative" data-aos="fade-right">
                <a href="https://www.youtube.com/watch?v=xt9imvFE_iI" className="glightbox play-btn mb-4"></a>
              </div>

              <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">


                <div className="icon-box" data-aos="fade-up">
                  <div className="icon"><i className="fa-solid fa-bullhorn"></i></div>
                  <h4 className="title"><a href="">Announcement</a></h4>
                  <p className="description">BT Group Selects Optiva for Next-Generation Application Server Upgrade to Build and Deploy Innovative New Services</p>
                </div>

                <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                  <div className="icon"><i className="fa-solid fa-clipboard-question"></i></div>
                  <h4 className="title"><a href="">Interview</a></h4>
                  <p className="description">AI will unleash new revenue growth in telco – Optiva</p>
                </div>

                <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                  <div className="icon"><i className="fa-solid fa-flag-checkered"></i></div>
                  <h4 className="title"><a href="">Report</a></h4>
                  <p className="description">Analysys Mason - Optiva monetisation platform</p>
                </div>

              </div>
            </div>
          </div>
        </section>

      </main>
      <footer id="footer">
        <div className="container d-lg-flex py-4">
          <div className="me-lg-auto text-center text-lg-start">
            <div className="copyright">
              &copy; <strong>TelcoX</strong>, Powered by <strong>Optiva</strong>. All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}


