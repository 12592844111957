import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Doughnut, Pie, PolarArea, Bar, Line } from 'react-chartjs-2'
import { deleteData, getData, postdata, putdata } from '../../../service/httputlity'
import { getAccountsettings } from '../../../service/utility'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'
import './Dashboard.scss'

import { Link } from 'react-router-dom'
import { Mixed } from '../../modules/widgets/components/Mixed'
import { List } from 'rsuite'
import { Lists } from '../../modules/widgets/components/Lists'

import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  ListsWidget8,
  MixedWidget1,
  MixedWidget10,
  MixedWidget11,
  MixedWidget13,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget8,
  TablesWidget10,
  TilesWidget1,
  TilesWidget2,
  TilesWidget3,
  TilesWidget4,
  TilesWidget5,
} from '../../../_metronic/partials/widgets'
import { Content } from '../../../_metronic/layout/components/Content'
import { Card5 } from '../../../_metronic/partials/content/cards/Card5'
import { Campaigns } from '../../modules/profile/components/Campaigns'

const API_URL = "https://api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&api_key=3fd2be6f0c70a2a598f084ddfb75487c&page=1";
const IMG_PATH = "https://image.tmdb.org/t/p/w1280";
const SEARCH_API = 'https://api.themoviedb.org/3/search/movie?api_key=3fd2be6f0c70a2a598f084ddfb75487c&query=';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

export default class Dashboard extends React.Component {
  state = {
    PlanCount: 0,
    ConsoleLogs: 0,
    CanReqCount: 0,
  }


  initMenu() {
    // getData('ConsoleLogs/getproductcount').then((d) => {
    //   this.setState({
    //     PlanCount:d.planCount,
    //     CanReqCount:d.canReqCount,
    //     ConsoleLogs:d.planCount,
    //   })
    // })
  }

  componentDidMount() {
    getData('version').then(
      (d) => {
        if (!d) {
          window.location.href = '/auth/login';
        }
      },
      (e) => {
        window.location.href = '/auth/login';
      }
    )
    this.initMenu();
    this.getMovies(API_URL);
  }

  sidebarItemsClicked(id) {
    this.setState({ selectedChild: id })
  }
  constructor(props) {
    super(props);
    this.state = {
      movies: [],
      searchTerm: ''
    };

    this.getMovies = this.getMovies.bind(this);
  }


  async getMovies(url) {
    const res = await fetch(url);
    const data = await res.json();
    this.setState({ movies: data.results });
  }

  getClassByRate(vote) {
    if (vote >= 8) {
      return "green";
    } else if (vote >= 5) {
      return "orange";
    } else {
      return "red";
    }
  }



  render() {
    const { movies, searchTerm } = this.state;
    return (
      <>
        <Content>
          <div className='row g-5 g-xl-10'>
            <div className='col-xl-4 col-md-4'>
              <MixedWidget2
                className='card card-xl-stretch mb-xl-8'
                chartColor='light'
                chartHeight='160px'
                strokeColor='#fe5000'
              />
              {/* <div class="card card-flush p-0" id="kt_player_widget_2">
                <div class="card-body bg-black pt-3">
                  <div class="mx-auto mb-5 bgi-no-repeat bgi-size-contain bgi-position-center rounded-circle w-75px h-75px"
                      style={{backgroundImage:`url('/media/stock/600x600/img-59.jpg')`}} >  
                  </div>
                  <div class="text-center mb-4">
                      <h2 class="text-white fw-bold">Strange Friends</h2>
                      <span class="text-white opacity-75 fw-semibold fs-4">Theresa Webb</span>
                  </div>
                </div>
                <div class="card-footer bg-primary p-0 pb-5">
                  <div class="mt-n10">
                      <div className='mb-2'>
                        <div class="d-flex flex-stack px-4 text-white opacity-75">
                            <span class="current-time" data-kt-element="current-time">0:00</span>
                            <span class="duration" data-kt-element="duration">5:53</span>                               
                        </div>
                        <input type="range" class="form-range" data-kt-element="progress" min="0" max="100" value="0" step="0.01" />                
                      </div>
                      <div class="d-flex flex-center">
                        <button class="btn btn-icon mx-1" data-kt-element="replay-button">
                        <i class="bi bi-arrow-repeat fs-4 text-white"></i>     
                        </button>
                        <button class="btn btn-icon mx-1" data-kt-element="play-prev-button">
                        <i class="bi bi-caret-left-fill fs-4 text-white"></i>
                        </button>
                        <button class="btn btn-icon mx-6 play-pause" data-kt-element="play-button">
                        <i class="bi bi-play-fill text-white fs-3x" data-kt-element="play-icon"></i>  
                        <i class="bi bi-pause-fill text-white fs-3x d-none" data-kt-element="pause-icon"></i>                   
                        </button>
                        <button class="btn btn-icon mx-1 next" data-kt-element="play-next-button">
                        <i class="bi bi-caret-right-fill fs-4 text-white"></i>
                        </button>
                        <button class="btn btn-icon mx-1" data-kt-element="shuffle-button">
                        <i class="bi bi-shuffle fs-4 text-white"></i>
                        </button>
                      </div>
                      <audio data-kt-element="audio-track-1">
                        <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-5.mp3" type="audio/mpeg" />
                      </audio>
                  </div>
                </div>
            </div> */}
            </div>
            <div className='col-xl-8 col-md-8'>
              <div className='row gx-5 gx-xl-10'>
                <div className='col-xl-7 col-md-8'>
                  <div class="card card-xl-stretch mb-xl-8">
                    {/* <h3 className="card-title fw-bold text-gray-900 p-5 mt-3 mb-0">Usage
                      <span className='fs-6 text-muted'> Stay on top of your usage with real-time analytics</span></h3> */}
                    <div className='fs-4 card-label fw-semibold m-5 text-gray-900'>Stay on top of your usage with real-time analytics</div>
                    <div class="card-body d-flex align-items-start p-5 pt-0">
                      <div class="row align-items-center mx-0 w-100 progress_circle">
                        <div class="col-md-4">
                          <div className='row align-items-center justify-content-center'>
                            <div class="progress pink">
                              <span class="progress-left">
                                <span class="progress-bar"></span>
                              </span>
                              <span class="progress-right">
                                <span class="progress-bar"></span>
                              </span>
                              <div class="progress-value">90%</div>
                            </div>
                            <div className='text-center fs-3 card-label fw-bold text-gray-900'>Data usage</div>
                            <div className='text-center fs-6 text-muted'>Optimize your data for what matters most</div>
                          </div>

                        </div>
                        <div class="col-md-4">
                          <div className='row align-items-center justify-content-center'>
                            <div class="progress blue">
                              <span class="progress-left">
                                <span class="progress-bar"></span>
                              </span>
                              <span class="progress-right">
                                <span class="progress-bar"></span>
                              </span>
                              <div class="progress-value">37.5%</div>
                            </div>
                            <div className='text-center fs-3 card-label fw-bold text-gray-900'>Voice usage</div>
                            <div className='text-center fs-6 text-muted'>Clear Conversations, anytime</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div className='row align-items-center justify-content-center'>
                            <div class="progress orange">
                              <span class="progress-left">
                                <span class="progress-bar"></span>
                              </span>
                              <span class="progress-right">
                                <span class="progress-bar"></span>
                              </span>
                              <div class="progress-value">50.5%</div>
                            </div>
                            <div className='text-center fs-3 card-label fw-bold text-gray-900'>SMS Usage</div>
                            <div className='text-center fs-6 text-muted'>Keep your messages flowing</div>
                          </div>
                        </div>
                      </div>
                      {/* 
                        <div class="row align-items-center mt-4 mx-0 w-100">
                            <div class="col-7 px-0">
                              <div class="d-flex flex-column content-justify-center">
                                  <div class="d-flex fs-6 fw-semibold align-items-center">
                                    <div class="bullet bg-success me-3" ></div>
                                    <div class="fs-5 fw-bold text-gray-600 me-5">Call Performance:</div>
                                    <div class="ms-auto fw-bolder text-gray-700 text-end">72.56%</div>
                                  </div>
                                  <div class="d-flex fs-6 fw-semibold align-items-center my-4">
                                    <div class="bullet bg-primary me-3" ></div>
                                    <div class="fs-5 fw-bold text-gray-600 me-5">Recurring Calls:</div>
                                    <div class="ms-auto fw-bolder text-gray-700 text-end">29.34%</div>
                                  </div>
                              </div>
                            </div>
                            <div class="col-5 d-flex justify-content-end px-0">
                              <div class="radialProgressBar progress-60">
                                  <div class="overlay">74</div>
                              </div>
                            </div>
                        </div>
                        */}
                    </div>
                  </div>
                  <div className='card card-xl-stretch mb-xl-8'>
                    <div className='card-body'>
                      <div id="kt_carousel_1_carousel"
                        className="carousel carousel-custom slide"
                        data-bs-ride="carousel"
                        data-bs-interval="5000">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <span className="fs-4 fw-bolder pe-2">Transactions</span>
                          <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                            <li
                              data-bs-target="#kt_carousel_1_carousel"
                              data-bs-slide-to="0"
                              className="ms-1 active"
                            ></li>
                            <li
                              data-bs-target="#kt_carousel_1_carousel"
                              data-bs-slide-to="1"
                              className="ms-1"
                            ></li>
                          </ol>
                        </div>
                        <div className="carousel-inner pt-8">
                          <div className="carousel-item active">
                            <div className='row g-2'>
                              <div className='col'>
                                <div className='card bg-orange-grad h-100'>
                                  <div class="card-body d-flex flex-column justify-content-between">
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-35px w-35px bg-white me-5'>
                                        <img src={toAbsoluteUrl('/media/svg/brands/netflix.png')} alt='Metronic' className='p-2' />
                                      </div>
                                      <div className='fs-5 fw-bold text-white m-0'>
                                        Netflix
                                      </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                      <div class="text-white fw-bold fs-2qx mb-0 mt-5">
                                        $15
                                      </div>
                                      <div class="text-white fw-semibold fs-6">
                                        Your Gateway to endless <span className='fw-bold fs-6'>entertainment</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col'>
                                <div className='card bg-purple-grad h-100'>
                                  <div class="card-body d-flex flex-column justify-content-between">
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-35px w-35px bg-white me-5'>
                                        <img src={toAbsoluteUrl('/media/svg/brand-logos/spotify.svg')} alt='Metronic' className='p-2' />
                                      </div>
                                      <div className='fs-5 fw-bold text-white m-0'>
                                        Spotify
                                      </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                      <div class="text-white fw-bold fs-2qx mb-0 mt-5">
                                        $18
                                      </div>
                                      <div class="text-white fw-semibold fs-6">
                                        Unlimited <span className='fw-bold fs-6'>music</span> at your fingertips
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className='row g-2'>

                              <div className='col'>
                                <div className='card bg-purple-grad h-100'>
                                  <div class="card-body d-flex flex-column justify-content-between">
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-35px w-35px bg-white me-5'>
                                        <img src={toAbsoluteUrl('/media/svg/brands/google-cloud.svg')} alt='Metronic' className='p-3' />
                                      </div>
                                      <div className='fs-5 fw-bold text-white m-0'>
                                        Google cloud
                                      </div>
                                    </div>
                                    <i class="ki-duotone ki-chart-pie-simple text-white fs-2hx ms-n1 flex-grow-1">
                                      <span class="path1"></span><span class="path2"></span></i>
                                    <div class="d-flex flex-column">
                                      <div class="text-white fw-bold fs-2qx mb-0 mt-5">
                                        $20
                                      </div>
                                      <div class="text-white fw-semibold fs-6">
                                        High-performance for<span className='fw-bold fs-6'> Cloud Computing </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col'>
                                <div className='card bg-orange-grad h-100'>
                                  <div class="card-body d-flex flex-column justify-content-between">
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-35px w-35px bg-white me-5'>
                                        <img src={toAbsoluteUrl('/media/svg/brands/playstation.svg')} alt='Metronic' className='p-3' />
                                      </div>
                                      <div className='fs-5 fw-bold text-white m-0'>
                                        PS Plus
                                      </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                      <div class="text-white fw-bold fs-2qx mb-0 mt-5">
                                        $30
                                      </div>
                                      <div class="text-white fw-semibold fs-6">
                                        PS Plus Your Next Adventure<span className='fw-bold fs-6'> Gaming </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <MixedWidget4
                      className='card-xl-stretch mb-xl-8'
                      image='/media/svg/brand-logos/reddit.svg'
                      color='primary'
                      title='Monthly Subscription'
                      date='Due: 27 Apr 2020'
                      progress='75%'
                      /> */}
                </div>
                <div className='col-xl-5 col-md-4 d-flex flex-column justify-content-evenly px-10'>

                  <div className='row'>
                    <div class="card mb-5 bg-org-pnk-prpl" >
                      <div class="card-body p-0 m-0">
                        <div class="row align-items-center">
                          <div class="col-sm-8 pe-0 mb-5 mb-sm-2">
                            <div class="d-flex justify-content-between flex-column h-100 pt-xl-5 pb-xl-2 ps-xl-7">
                              <div class="mb-6">
                                <h3 class="fw-bold d-block fs-3 text-white">Upgrade Your Plan</h3>
                                <span class="fw-semibold text-white opacity-75 fs-5">Only for $15 per month</span>
                              </div>
                              <div class="m-0">
                                <a href="#" class="btn btn-sm btn-color-orange text-orange bg-white bg-hover-opacity-75 fw-bold" >
                                  GO PRO <i className='fa fa-arrow-right'></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4" >
                            <img src="/media/svg/illustrations/easy/7.svg" class="h-150px h-lg-170px my-n6" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='card card-xl-stretch mb-xl-6 p-0'>
                      <div className='card-body p-0'>
                        <div id="kt_carousel_2_carousel"
                          className="carousel carousel-custom slide"
                          data-bs-ride="carousel"
                          data-bs-interval="5000">
                          <div className="d-flex align-items-center justify-content-center overflow-hidden flex-wrap p-0 mt-0">
                            <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                              {movies.map((movie, index) => {
                                const activeClass = index === 0 ? 'active' : '';
                                return (
                                  <li
                                    data-bs-target="#kt_carousel_2_carousel"
                                    data-bs-slide-to={index}
                                    className={`ms-1 ${activeClass}`}
                                  ></li>
                                );
                              })}
                            </ol>

                          </div>
                          <div className="carousel-inner">
                            <span className="fs-4 fw-bolder p-4 position-absolute z-index-0">Latest Movies</span>
                            {movies.map((movie, index) => {
                              const activeClass = index === 0 ? 'active' : '';
                              return (
                                <div key={movie.id} className={` carousel-item z-index-0 ${activeClass}`}>
                                  <div className='movie z-index-0'>
                                    <img src={IMG_PATH + movie.poster_path} alt={movie.title} />
                                    <div className="movie-info">
                                      <h4>{movie.title}</h4>
                                      <span className={this.getClassByRate(movie.vote_average)}>
                                        2$
                                        {/* {movie.vote_average} */}
                                      </span>
                                    </div>
                                    <div className="overview">
                                      <h3 className='text-dark'>Overview</h3>
                                      {movie.overview}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='row h-150px'>
                  <div className='col-xl-4 col-md-3'>
                  </div>
                  <div className='col-xl-8 col-md-9'>
                      <div class="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-50  bg-light"
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/wave-bg-dark.svg')})`,}}>
                      <div class="p-5">
                        <span class="card-label fw-bold text-gray-900">International Calls</span>
                      </div>
                      <div class="card-body d-flex align-items-center justify-content-around py-5">
                        <div class="d-flex flex-column align-items-center">
                            <span class="fs-2qx text-gradient bg-orange-grad fw-bold pb-3">120 min</span>
                        </div>
                      </div>
                      <div class="card-footer py-3 bg-black opacity-75"  >
                        <div className=" d-flex justify-content-between fw-bold cursor-pointer">
                            <span className="fs-6">Add More Data</span>
                            <i className="fa fa-plus fs-2 fw-bold"></i>
                        </div>
                      </div>
                  </div>
                </div>
                </div>
                <div className='row h-150px'>
                    <div className='col-xl-8 col-md-9'>
                      <div class="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-50  bg-light"
                      style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/wave-bg-dark.svg')})`,}}>
                      <div class="p-5">
                          <span class="card-label fw-bold text-gray-900">Video Calls</span>
                      </div>
                      <div class="card-body d-flex align-items-center justify-content-around py-5">
                          <div class="d-flex flex-column align-items-center">
                            <span class="fs-2qx text-gradient bg-orange-grad fw-bold pb-3">10 min</span>
                          </div>
                      </div>
                      <div class="card-footer py-3 bg-black opacity-75"  >
                          <div className=" d-flex justify-content-between fw-bold cursor-pointer">
                            <span className="fs-6">Add More Data</span>
                            <i className="fa fa-plus fs-2 fw-bold"></i>
                          </div>
                      </div>
                    </div>
                </div>
                <div className='col-xl-4 col-md-3'>
                </div>
              </div> */}
                </div>

              </div>

            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='card card-xl-stretch mb-xl-8'>
                <div className='card-body'>
                  <div id="kt_carousel_3_carousel"
                    className="carousel carousel-custom slide"
                    data-bs-ride="carousel"
                    data-bs-interval="5000">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <span className="fs-4 fw-bolder pe-2">Weather</span>
                      <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                        <li
                          data-bs-target="#kt_carousel_3_carousel"
                          data-bs-slide-to="0"
                          className="ms-1 active"
                        ></li>
                        <li
                          data-bs-target="#kt_carousel_3_carousel"
                          data-bs-slide-to="1"
                          className="ms-1"
                        ></li>
                        <li
                          data-bs-target="#kt_carousel_3_carousel"
                          data-bs-slide-to="2"
                          className="ms-1 active"
                        ></li>
                        <li
                          data-bs-target="#kt_carousel_3_carousel"
                          data-bs-slide-to="3"
                          className="ms-1"
                        ></li>
                        <li
                          data-bs-target="#kt_carousel_3_carousel"
                          data-bs-slide-to="4"
                          className="ms-1"
                        ></li>
                      </ol>
                    </div>
                    <div className="carousel-inner pt-4">
                      <div className="carousel-item active">
                        <div class="container-sun card bg-green-grad h-150px">
                          <div class="box-shadow-sun bg-warning"></div>
                          <div class="content-sun bg-dark">
                            <div class="image-sun">
                              <div class="sun"></div>
                            </div>
                            <div class="text-sun">
                              <p class="city">Montevideo, Uruguay</p>
                              <p class="day">MONDAY</p>
                              <p class="grade">24°C</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div class="container-rain card bg-green-grad h-150px">
                          <div class="box-shadow-rain"></div>
                          <div class="content-rain bg-dark">
                            <div class="image-rain">
                              <div class="rain-cloud">
                                <div class="raindrop"></div>
                                <div class="raindrop"></div>
                                <div class="raindrop"></div>
                              </div>
                            </div>
                            <div class="text-rain">
                              <p class="city">Bergen, Noruega</p>
                              <p class="day">MONDAY</p>
                              <p class="grade">8°C</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div class="container-sun-and-cloud card bg-green-grad h-150px">
                          <div class="box-shadow-sun-and-cloud"></div>
                          <div class="content-sun-and-cloud bg-dark">
                            <div class="image-sun-and-cloud">
                              <div class="sun-inner"></div>
                              <div class="cloud-sun-inner"></div>
                            </div>
                            <div class="text-sun-and-cloud">
                              <p class="city">Nairobi, Kenia</p>
                              <p class="day">MONDAY</p>
                              <p class="grade">16°C</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div class="container-cloud card bg-green-grad h-150px">
                          <div class="box-shadow-cloud"></div>
                          <div class="content-cloud bg-dark">
                            <div class="image-cloud">
                              <div class="cloud"></div>
                            </div>
                            <div class="text-cloud">
                              <p class="city">Terranova, Canadá</p>
                              <p class="day">MONDAY</p>
                              <p class="grade">11°C</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div class="container-storm card bg-green-grad h-150px">
                          <div class="box-shadow-storm"></div>
                          <div class="content-storm bg-dark">
                            <div class="image-storm">
                              <div class="cloud-storm">
                                <div class="raindrop-storm"></div>
                                <div class="raindrop-storm"></div>
                                <div class="raindrop-storm"></div>
                                <div class="bolt"></div>
                              </div>
                            </div>
                            <div class="text-storm">
                              <p class="city">Maracaibo, Venezuela</p>
                              <p class="day">MONDAY</p>
                              <p class="grade">11°C</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <ListsWidget8 className='card-xl-stretch mb-5 mb-xl-8' />
            </div>
            <div className='col-md-4'>
              <MixedWidget8
                className='card-xl-stretch mb-xl-8'
                chartColor='primary'
                chartHeight='150px'
              />

            </div>
          </div>
          <div>
          </div>
        </Content>


      </>
    )
  }
}
