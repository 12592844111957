
import {useEffect, useRef, FC} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}

const MixedWidget2: FC<Props> = ({className, chartColor, chartHeight, strokeColor}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className="card-title fw-bold text-gray-900">Billing Staticstics</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
          <i className="fa-solid fa-circle-chevron-down fs-1 text-orange"></i>
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card p-2'>
          {/* begin::Row */}
          <div className='row g-2'>
            
          <div className='col'>
          <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100 bg-light" >
                          <div className="card-header py-5">
                          <a href='#' className='card-label fs-3 fw-bold text-gray-900'>
                          Internet Data
                            </a>
                            <div className='fs-6 text-muted'>Track your Internet data consumtion effortlessly</div>
                          </div>
                          <div className="card-body d-flex align-items-end mb-3 py-3 justify-content-around">
                         
                            <div className="d-flex flex-center rounded-circle h-50px w-50px bg-purple-grad" 
                            style={{border: `1px dashed rgba(255, 255, 255, 0.4)` ,}} >
                              <i className="fa-solid fa-wifi text-white fs-1 lh-0"></i>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <span className="fs-2qx fw-bold mx-3">1.2k</span>
                              <span className="d-block text-gray-800 fs-6">/20GB</span>
                            </div>
                          </div>
                          <div className="card-footer py-5 bg-dark opacity-75" >
                  
                        
                          <div className="d-flex justify-content-between fw-bold cursor-pointer">
                            <span className="fs-5">Add More Data</span>
                            <i className="fa fa-plus fs-2 fw-bold"></i>
                          </div>
                        </div>
              </div>
            </div>
            <div className='col'>
            <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100 bg-light" >
            {/* style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/wave-bg-dark.svg')})`, backgroundColor: `#101010`}}  */}
                      
                      
                    <div className="card-header py-5">
                    <a href='#' className='card-label fs-3 fw-bold text-gray-900'>
                    Voice Minutes
                      </a>
                      <div className='fs-6 text-muted'>Monitor your call duration and stay connected</div>
                    </div>
                    <div className="card-body d-flex align-items-end mb-3 py-3 justify-content-around">
                      <div className="d-flex flex-center rounded-circle h-50px w-50px bg-purple-grad" 
                      style={{border: `1px dashed rgba(255, 255, 255, 0.4)` ,}} >
                        <i className="fa-solid fa-phone-volume text-white fs-1 lh-0"></i>
                      </div>
                        <div className="d-flex flex-column align-items-center">
                          <span className="fs-2qx fw-bold mx-3">40</span>
                          <span className="d-block text-gray-800 fs-6">/60 min</span>
                        </div>
                    </div>
                    <div className="card-footer py-5 bg-dark opacity-75">
                  
                    
                      <div className="d-flex justify-content-between fw-bold cursor-pointer">
                        <span className="fs-5">Add More Minutes</span>
                        <i className="fa fa-plus fs-2 fw-bold"></i>
                      </div>
                    </div>
          </div>
            </div>
           
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0 pt-2'>
            {/* begin::Col */}
            <div className='col'>
            <div className="card card-flush h-xl-100 rounded pt-5" >
                  <div className="card-body d-flex  justify-content-between mb-3 py-3">
                    <div className="d-flex flex-column">
                      <span className="fs-3 fw-bold">Your Usage History</span>
                      <div className="fs-6 text-muted">
                        Review your data and call usage overtime
                      </div>
                    </div>
                    <div className="d-flex flex-center rounded-circle h-60px w-60px" 
                      style={{border: `1px dashed rgba(255, 255, 255, 0.4)` ,}} >
                        <i className="fa-solid fa-history text-orange fs-1 lh-0"></i>
                      </div>

                  </div>
          </div>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const color = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Data Used',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + 'GB'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export {MixedWidget2}
