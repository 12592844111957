import React from 'react'
import {getData, postdata} from '../../../../service/httputlity'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

export default class Trigger extends React.Component {
 
  triggerClick(){
    document.getElementById("triggerbtn").remove();
      postdata('trigger/triggernotifications').then(
      (d) => {
        if(d)
          alert("Done")
        else{
          alert("Failed")
        }
      },
      (e) => {
        alert("Failed")
      }
    )
  }

  render() {
    return (
        <div>
         <div className='text-center mb-5'>
          <h1 className='mb-5 fs-2qx'>Get Notification</h1>
          <div className='text-gray-700 fw-bold fs-3'>
          Mail from TelcoX
          </div>
        </div>
        <div className='symbol symbol-150px d-flex align-items-center justify-content-center'>
            <img src={toAbsoluteUrl('/media/illustrations/sigma-1/16.png')} alt='' w />
        
        </div>
          <button onClick={()=>{this.triggerClick();}} id="triggerbtn" className='btn btn-lg  bg-org-pnk-prpl w-100 m-5' >Trigger Notification</button>
        </div>
    )
  }
}
