/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-75 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='/auth' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='/auth' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='/auth' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      
         {/* begin::Content */}
         <div  className='d-flex flex-column flex-center p-20 w-lg-50 order-1 order-lg-2 bg-dark' >
        <div className='d-flex flex-column flex-center'>
        <a href='#' className='mb-12'>
           {/* <img
             alt='Logo'
             src={toAbsoluteUrl('/media/logos/default-dark.svg')}
             className='theme-dark-show h-45px'
           />
           <img
             alt='Logo'
             src={toAbsoluteUrl('/media/logos/Optiva_Logo_white.png')}
             className='theme-light-show h-45px'
           ></img> */}
           <h1 className='text-white'>TelcoX</h1>
         </a>
        </div>
        <div className='text-white fs-2 text-center'>
        Simplify, Unify and Monetize
          </div>
         <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100 pt-5 h-100 bgi-size-cover bgi-position-center'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/dozzy-1/17.png')})`}}>
        
        
        </div>
         {/* end::Content */}
       
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
